<template>
	<div>
		<a-spin :spinning="loading">
			<a-row style="margin-bottom:20px;">
				<a-col :span="18">
					<a-button @click="onSearchBtn">
						<template #icon>
							<Icon icon="SearchOutlined" />
						</template>
						查询
					</a-button>
					<exportModal class="ui-exportBrn" v-permission="['pc_assets_transferManage_export']" :type="type" :searchData="selIdsData" :condition="condition" :role="role" :isAll="1" :isProject="true">
						<a-button>
							<template #icon>
								<Icon icon="UploadOutlined" />
							</template>
							导出</a-button>
					</exportModal>
					<exportModal class="ui-exportBrn" v-permission="['pc_assets_transferManage_export']" style="margin-right: 16px;" :type="type" :searchData="searchData" :condition="condition" :role="role" :isAll="0" :isProject="true">
						<a-button>
						<template #icon>
							<Icon icon="LogoutOutlined" />
						</template>
						导出全部</a-button>
					</exportModal>
					<a-button @click="onSearch()">
						<template #icon>
							<Icon icon="ReloadOutlined" />
						</template>
						刷新
					</a-button>
				</a-col>
			</a-row>

			<div v-show="isSearch" style="margin:20px 0;">
				<a-form class="label-width ui-form" ref="formRef" :model="formSearch" layout="inline">
					<a-form-item class="ui-form__item" label="操作人姓名">
						<a-input v-model:value="formSearch.username" placeholder="请输入操作人姓名"></a-input>
					</a-form-item>
					<a-form-item class="ui-form__item" label="操作人电话">
						<a-input v-model:value="formSearch.phone" placeholder="请输入操作人电话"></a-input>
					</a-form-item>
					<a-form-item class="ui-form__item" label="主管单位">
						<a-select placeholder="请选择主管单位" allow-clear style="width: 190px;" v-model:value="formSearch.mangerUnitId">
							<a-select-option v-for="(item, index) in $store.state.count.unitList" :key="item.id"
								:value="item.id">{{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
					<a-form-item class="ui-form__item" label="项目名称">
						<a-input v-model:value="formSearch.name" placeholder="请输入项目名称"></a-input>
					</a-form-item>
					<a-form-item label="实施单位" class="ui-form__item">
						<a-select placeholder="请选择实施单位" allow-clear style="width: 190px;" v-model:value="formSearch.executeUnitId">
							<a-select-option v-for="(item, index) in $store.state.count.unitList" :key="item.id"
								:value="item.id">{{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
					<a-form-item class="ui-form__item" label="施工单位">
						<a-input v-model:value="formSearch.constructionCompany" placeholder="请输入施工单位名称"></a-input>
					</a-form-item>
					<a-form-item class="ui-form__item" label="申请时间">
						<!-- 缺 -->
						<a-range-picker show-time v-model:value="clearTime" @change="onChangeFun" />
					</a-form-item>
					<a-form-item class="ui-form__item" label="操作类型">
						<!-- 补文档 -->
						<a-select placeholder="请选择操作类型" allow-clear style="width: 190px;" v-model:value="formSearch.operation">
							<a-select-option value="新增项目">新增项目</a-select-option>
							<a-select-option value="录入信息">录入信息</a-select-option>
							<a-select-option value="修改项目">修改项目</a-select-option>
						</a-select>
					</a-form-item>
					<a-form-item class="ui-form__item">
						<a-button @click="onReset">重置</a-button>
						<a-button type="primary" @click="onSearch">搜索</a-button>
					</a-form-item>
				</a-form>
			</div>
			<!-- rowSelectionType="checkbox" -->
			<c-Table ref="cTable" :columns="columns" :isRequest="true" :requestFun="getDataFun"
				rowSelectionType="checkbox" :searchData="searchData" @rowSelected="rowSelected"
				size="small" bordered draggable @changeSort="changeSort">
				<!-- <template #headerCell="{ column }">
					<template v-if="column.key === 'index'">
						<div @click="onShowSelectModal">
							<Icon icon="SettingFilled"></Icon>
						</div>
					</template>
				</template> -->
				<template #bodyCell="{ column, record, index, pagination }">
					<template v-if="column.key === 'index'">
						{{ ((pagination.current - 1) * pagination.pageSize) + index + 1 }}
					</template>
					<template v-if="column.key === 'createTime'">
						{{ transDateTime(record.createTime) }}
					</template>
					<template v-if="column.key === 'mangerUnit'">
						<div v-if="record.extra !== null">
							{{ JSON.parse( record.extra).mangerUnit }}
						</div>
						<div v-else>/</div>
					</template>
					<template v-if="column.key === 'projectName'">
						<div v-if="record.extra !== null" style="color: #1890ff;" @click="onDetail(record)">
							{{ JSON.parse( record.extra).projectName }}
						</div>
						<div v-else>/</div>
					</template>
					<template v-if="column.key === 'executeUnit'">
						<div v-if="record.extra !== null">
							{{ JSON.parse( record.extra).executeUnit }}
						</div>
						<div v-else>/</div>
					</template>
					<template v-if="column.key === 'constructionCompany'">
						<div v-if="record.extra !== null && JSON.parse( record.extra).constructionCompany.length !== 0">
							{{ JSON.parse( record.extra).constructionCompany }}
						</div>
						<div v-else>/</div>
					</template>
					<template v-if="column.key === 'operateType'">
						<div v-if="record.operateType.length !== 0">
							{{ record.operateType }}
						</div>
						<div v-else>/</div>
					</template>
				</template>
			</c-Table>
			<!-- <a-table rowKey="id" :columns="columns" bordered draggable :data-source="dataList" :pagination="pagination" :customRow="customRow" @resizeColumn="handleResizeColumn"
                :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }">
               <template #headerCell="{ column }">
                    <template v-if="column.key === 'index'">
                        <div @click="onShowSelectModal">
                            <Icon icon="SettingFilled"></Icon>
                        </div>
                    </template>
                </template>
                <template #bodyCell="{ column, record, index }">
                    <template v-if="column.key === 'index'">
                        {{ ((pagination.current - 1) * pagination.pageSize) + index + 1 }}
                    </template>
                </template>
            </a-table> -->
			<!-- <p>选中{{ selectedLength }}条记录，投资额总计{{ 0 }}元，已拨付数总计{{ 0 }}元，待拨付数总计{{ 0 }}元</p> -->
		</a-spin>
	</div>
</template>

<script>
	import filesUpload from '@/components/upload/filesUpload.vue';
	import {
		Icon
	} from '@/components/icon/icon.js';
	import {
		getOperateLog
	} from '@/service/modules/project.js'
	import cTable from '@/components/cTable/index.vue';
	import exportModal from '@/components/exportModal/exportModal.vue';
	export default {
		components: {
			Icon,
			cTable,
			exportModal
		},
		data() {
			return {
				loading: false,
				isSearch: false,
				formSearch: {},
				searchData: {},
				clearTime: [], // 存储时间 用来清空重置
				getDataFun: getOperateLog,
				columns: [{
						title: '序号',
						dataIndex: 'index',
						key: 'index',
						rowDrag: true,
						width: 100,
						resizable: true,
					},
					{
						title: '操作人',
						dataIndex: 'username',
						key: 'username',
						resizable: true,
						width: 200,
						sorter: {
							compare: (a, b) => a.username - b.username,
							multiple: 9
						}
					},
					{
						title: '操作人电话',
						dataIndex: 'phone',
						key: 'phone',
						width: 200,
						resizable: true,
						sorter: {
							compare: (a, b) => a.phone - b.phone,
							multiple: 8
						}
					},
					// {
					// 	title: '申请时间',
					// 	dataIndex: 'createTime',
					// 	key: 'createTime',
					// 	resizable: true,
					// 	sorter: {
					// 		compare: (a, b) => a.createTime - b.createTime,
					// 		multiple: 7
					// 	}
					// },
					{
						title: '主管单位',
						dataIndex: 'mangerUnit',
						key: 'mangerUnit',
						width: 400,
						resizable: true,
						sorter: {
							compare: (a, b) => a.mangerUnit - b.mangerUnit,
							multiple: 6
						}
					},
					{
						title: '项目名称',
						dataIndex: 'projectName',
						key: 'projectName',
						width: 300,
						resizable: true,
						sorter: {
							compare: (a, b) => a.projectName - b.projectName,
							multiple: 5
						}
					},
					{
						title: '实施单位',
						dataIndex: 'executeUnit',
						key: 'executeUnit',
						width: 300,
						resizable: true,
						sorter: {
							compare: (a, b) => a.executeUnit - b.executeUnit,
							multiple: 4
						}
					},
					{
						title: '施工单位',
						dataIndex: 'constructionCompany',
						key: 'constructionCompany',
						width: 200,
						resizable: true,
						sorter: {
							compare: (a, b) => a.constructionCompany - b.constructionCompany,
							multiple: 3
						}
					},
					{
						title: '操作类型',
						dataIndex: 'operateType',
						key: 'operateType',
						width: 200,
						resizable: true,
						sorter: {
							compare: (a, b) => a.operateType - b.operateType,
							multiple: 2
						}
					},
					{
						title: '操作内容',
						dataIndex: 'content',
						width: 200,
						key: 'content',
						resizable: true,
						sorter: {
							compare: (a, b) => a.content - b.content,
							multiple: 2
						}
					},
					{
						title: '操作时间 ',
						dataIndex: 'createTime',
						width: 250,
						key: 'createTime',
						resizable: true,
						sorter: {
							compare: (a, b) => a.createTime - b.createTime,
							multiple: 1
						}
					}
				],
				dataList: [{
					key: 1,
					name: '张三',
					phone: '13129810293',
					time: '2022年12月7日12：32',
					unit: '县工商局',
					projectName: 'XXXX项目',
					applyUnit: '县工商局',
					buildName: 'XXX公司',
					sort: '新增项目',
					isImprotant: '2022年12月7日12：32 ',
				}, {
					key: 2,
					name: '李四',
					phone: '13129810293',
					time: '2022年12月7日12：32',
					unit: '县工商局',
					projectName: 'XXXX项目',
					applyUnit: '县工商局',
					buildName: 'XXX公司',
					sort: '建设用地储备、项目立项',
					isImprotant: '2022年12月7日12：32 ',
				}, {
					key: 3,
					name: '张三',
					phone: '13129810293',
					time: '2022年12月7日12：32',
					unit: '县工商局',
					projectName: 'XXXX项目',
					applyUnit: '县工商局',
					buildName: 'XXX公司',
					sort: '新增项目',
					isImprotant: '2022年12月7日12：32 ',
				}, {
					key: 4,
					name: '张三',
					phone: '13129810293',
					time: '2022年12月7日12：32',
					unit: '县工商局',
					projectName: 'XXXX项目',
					applyUnit: '县工商局',
					buildName: 'XXX公司',
					sort: '新增项目',
					isImprotant: '2022年12月7日12：32 ',
				}, {
					key: 5,
					name: '张三',
					phone: '13129810293',
					time: '2022年12月7日12：32',
					unit: '县工商局',
					projectName: 'XXXX项目',
					applyUnit: '县工商局',
					buildName: 'XXX公司',
					sort: '新增项目',
					isImprotant: '2022年12月7日12：32 ',
				}, {
					name: '张三',
					phone: '13129810293',
					time: '2022年12月7日12：32',
					unit: '县工商局',
					projectName: 'XXXX项目',
					applyUnit: '县工商局',
					buildName: 'XXX公司',
					sort: '新增项目',
					isImprotant: '2022年12月7日12：32 ',
				}, {
					name: '张三',
					phone: '13129810293',
					time: '2022年12月7日12：32',
					unit: '县工商局',
					projectName: 'XXXX项目',
					applyUnit: '县工商局',
					buildName: 'XXX公司',
					sort: '新增项目',
					isImprotant: '2022年12月7日12：32 ',
				}],
				selectedRowKeys: [],
				selectedLength: 0,
				type: 'projectRequestExportStrategy', // 导出类型
				role: 1, // 导出角色
				condition: '3', // 导出条件
				selIdsData:{},
			}
		},
		mounted() {
			this.loading = false;
		},
		created() {
			this.onSearch()
		},
		methods: {
			onDetail(item) {
				// this.$refs.saveModalRef.open(item)
				this.$router.push({
					name: 'projectPreview',
					query: {
						id: JSON.parse(item.extra).projectId,
						stage: item.stage,
						isPreview: true,
					}
				})
			},
			onSearchBtn() {
				this.isSearch = !this.isSearch;
			},
			onSearch() {
				this.searchData = JSON.parse(JSON.stringify(this.formSearch));
				// this.pagination.current = 1;
				this.$nextTick(() => {
					this.$refs.cTable.toQuery();
				})
			},
			rowSelected(item){
				this.selIdsData.ids = item.keys.join(',')
			},
			onReset() {
				this.$refs.formRef.resetFields();
				this.formSearch = {}
				this.clearTime = [];
				this.onSearch();
			},
			async getData() {},
			onExport() {

			},
			handleResizeColumn(w, col) {
				col.width = w;
			},
			customRow(record, index) {
				return {
					draggable: true,
					style: {
						cursor: 'pointer'
					},
					onDragstart: (event) => {
						// 兼容IE
						var ev = event || window.event
						// 阻止冒泡
						ev.stopPropagation()
						// 得到源目标数据
						this.sourceIndex = index;
					},
					onDragover: (event) => {
						// 兼容 IE
						var ev = event || window.event
						// 阻止默认行为
						ev.preventDefault()
					},
					onDrop: (event) => {
						// 兼容IE
						var ev = event || window.event
						// 阻止冒泡
						ev.stopPropagation()
						// 得到目标数据
						// this.targetObj = record;
						let itemData = this.list.splice(this.sourceIndex, 1);
						this.list.splice(index, 0, itemData[0]);
					}
				}
			},
			onSelectChange(selectedRowKeys) {
				this.selectedRowKeys = selectedRowKeys;
				this.selectedLength = selectedRowKeys.length;
			},
			onChangeFun(date, dateString) { // 时间变化的回调
				// this.clearTime = date
				let starDate = new Date(dateString[0])
				let endDate = new Date(dateString[1])
				// console.log(starDate.valueOf(), endDate.valueOf());
				this.formSearch.startTime = starDate.valueOf()/1000
				this.formSearch.endTime = endDate.valueOf()/1000
			},
			changeSort(order,columnKey){
				const sort = order == 'ascend' ? 'asc' : order == 'descend' ? 'desc' : null;
				this.formSearch.sort = [{
					key:columnKey,
					sort
				}]
				if(!sort){
					delete this.formSearch.sort;
				}
				this.onSearch();
			}
		}
	}
</script>

<style scoped>
.ui-exportBrn {
	margin-left: 16px;
}
</style>
